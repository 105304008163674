<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <Button
          icon="pi pi-times"
          class="p-button-danger mr-2"
          @click="$router.go(-1)"
        />
      </template>

      <template #right>
        <Button icon="pi pi-print" @click="printPage()" label="Print" />
      </template>
    </Toolbar>
    <div id="print">
      <DataTable
        :ref="'dt'"
        :value="list"
        class="p-datatable-gridlines p-datatable-sm"
        showGridlines
        dataKey="month"
        :rowHover="true"
      >
        <template #empty>Keine Daten</template>
        <template #loading>Daten werden geladen, bitte warten... </template>
        <Column field="month" header="Month">
          <template #body="slotProps">
            {{ slotProps.data.month }}
          </template>
        </Column>

        <Column field="total" header="Gesamt">
          <template #body="slotProps">{{
            getTotal(slotProps.data.list)
          }}</template>
        </Column>
        <Column field="workHours" header="Std./Woche">
          <template #body="slotProps">{{
            getTotalWorkHours(slotProps.data.month)
          }}</template>
        </Column>

        <Column field="plusOrMines" header="+ / -">
          <template #body="slotProps">{{
            getPlusOrMines(slotProps.data)
          }}</template>
        </Column>
        <Column field="holydays" :header="'Urlaub/' + user.holydays + 'Tag'">
          <template #body="slotProps">
            {{
              slotProps.data.list
                .map((el) => el.holydays)
                .reduce((a, b) => a + b, 0)
            }}
          </template>
        </Column>
        <Column field="sickdays" header="Krank/Tag">
          <template #body="slotProps">
            {{
              slotProps.data.list
                .map((el) => el.sickdays)
                .reduce((a, b) => a + b, 0)
            }}
          </template>
        </Column>

        <ColumnGroup type="footer">
          <Row>
            <Column footer="" :footerStyle="{ 'text-align': 'center' }" />
            <Column :footerStyle="{ 'text-align': 'center' }">
              <template #footer>
                <span> </span>
              </template>
            </Column>
            <Column footer="" :footerStyle="{ 'text-align': 'center' }" />
            <Column footer="" :footerStyle="{ 'text-align': 'center' }" />
            <Column
              :footer="getHolyDays()"
              :footerStyle="{ 'text-align': 'center' }"
            />
            <Column
              :footer="getSickDays()"
              :footerStyle="{ 'text-align': 'center' }"
            />
          </Row>
        </ColumnGroup>
      </DataTable>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      list: [],
      year: null,
      id: null,

      user: {
        workHours: 0,
        holydays: 0,
      },
    };
  },
  methods: {
    getTotal(list) {
      let totalMinutes = 0;
      for (const item of list) {
        if (item && item.signIn && item.signOut) {
          const end = moment(item.signOut, "H:mm");
          const start = moment(item.signIn, "H:mm");

          let diff = Number(end.diff(start, "minutes"));

          const pause = item.pause ? Number(item.pause) : 0;

          let total = diff - pause;

          if (item.publicHolidays == 1) {
            total = total * 2;
          }
          totalMinutes = totalMinutes + total;
        }
      }
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.trunc(totalMinutes % 60);

      return this.$timeByHours(hours, minutes);
    },
    getTotalWorkHours(month) {
      const workHoursByMIntes = Number(this.user.workHours) * 60;
      const day = workHoursByMIntes / 7;
      const monthDays = moment(month, "MM-YYYY").daysInMonth();

      const total = Number(monthDays) * day;
      const hours = Math.floor(total / 60);
      const minutes = Math.trunc(total % 60);

      return this.$timeByHours(hours, minutes);
    },

    getTotalByMintes(list) {
      let totalMinutes = 0;
      for (const item of list) {
        if (item && item.signIn && item.signOut) {
          const end = moment(item.signOut, "H:mm");
          const start = moment(item.signIn, "H:mm");

          let diff = Number(end.diff(start, "minutes"));

          const pause = item.pause ? Number(item.pause) : 0;

          let total = diff - pause;

          if (item.publicHolidays == 1) {
            total = total * 2;
          }
          totalMinutes = totalMinutes + total;
        }
      }
      return totalMinutes;
    },
    getTotalWorkHoursByMintes(month) {
      const workHoursByMIntes = Number(this.user.workHours) * 60;
      const day = workHoursByMIntes / 7;
      const monthDays = moment(month, "MM-YYYY").daysInMonth();

      return Number(monthDays) * day;
    },

    getPlusOrMines(data) {
      const totalMinutes =
        this.getTotalByMintes(data.list) -
        this.getTotalWorkHoursByMintes(data.month);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.trunc(totalMinutes % 60);

      return this.$timeByHours(hours, minutes);
    },

    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(
        this.$htmlPrint(
          stylesHtml,
          prtHtml,
          `${this.$route.params.from} / ${this.$route.params.to}`
        )
      );
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    getHolyDays() {
      let total = 0;
      for (const item of this.list) {
        for (const itm of item.list) {
          total = total + itm.holydays;
        }
      }
      return this.user.holydays - total;
    },

    getSickDays() {
      let total = 0;
      for (const item of this.list) {
        for (const itm of item.list) {
          total = total + itm.sickdays;
        }
      }
      return total;
    },

    getData(val) {
      const month = moment(val, "YYYY");

      this.$http
        .post(`payroll/search?limit=1000000000000`, {
          adminsId: this.id,
          year: month.format("YYYY"),
        })
        .then(
          (response) => {
            const list = response.data.data.items;
            const monthList = [];
            for (const item of list) {
              const indx = monthList.findIndex(
                (itm) => itm.month == item.month
              );
              if (indx > -1) {
                monthList[indx].list.push(item);
              } else {
                monthList.push({
                  month: item.month,
                  list: [item],
                });
              }
            }
            this.list = monthList;
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
  },
  created() {
    const aNum = window.prompt("Enter The Password", "");

    console.log(aNum);
    if (aNum != "555" || aNum == "") {
      this.$router.push("/admin/users");
    } else {
      this.id = this.$route.params.id;
      this.year = this.$route.params.year;
      this.getData(this.year);
      this.$http.get(`admins/${this.id}`).then(
        (res) => {
          this.user = res.data.data;
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    }
    // this.getDays();
    // this.getData();
  },
};
</script>
